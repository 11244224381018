<script setup lang="ts">
import ui from '/~/core/ui'
import Controls from '/~/extensions/bank-file-upload/components/common/controls.vue'
import Notification from '/~/extensions/bank-file-upload/components/common/notification.vue'
import Pagination from '/~/extensions/bank-file-upload/components/common/pagination.vue'
import UploadedFile from '/~/extensions/bank-file-upload/components/common/uploaded-file.vue'
import PendingPayeesTable from '/~/extensions/bank-file-upload/components/pending-payees/table/pending-payees-table.vue'
import LayoutMain from '/~/extensions/bank-file-upload/layouts/main.vue'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import {
  useBatchOrder,
  useBatchOrderPendingPayees,
  useBatchOrderNewPayees,
  useBatchOrderStatusHandler,
} from '/~/composables/batch-order'
import { useLocalization } from '/~/composables/localization'

const { pendingPayees, goToCheckout } = useBatchOrderPendingPayees()
const { missingPayees } = useBatchOrderNewPayees()
const { translate } = useLocalization()

missingPayees.payeeList.resetLocal()
pendingPayees.load(1)

const { batchOrder, onCancelBatchOrder, isPayeesSyncedBatchOrder } =
  useBatchOrder()
const { batchOrderStatusHandler } = useBatchOrderStatusHandler()

const tableLabels = [
  { name: 'Payee name', class: 'px-5 w-52' },
  { name: 'Account name', class: 'px-5 w-52' },
  { name: translate('bankAccount.detailsTitle'), class: 'px-5' },
  { name: 'Reference*', class: 'ml-auto px-5 w-40' },
  { name: 'Amount', class: 'px-5 w-28' },
  { name: `Payee ${translate('payment.businessNumber')}`, class: 'px-5 w-40' },
]

const cancelButtonText = 'Cancel'
const continueButtonText = 'Continue'
</script>

<template>
  <layout-main>
    <component
      :is="ui.mobile ? BaseAsidePage : 'div'"
      :title="ui.mobile && 'Upload file details'"
      :no-padding="ui.mobile && true"
      :back="ui.mobile && { name: 'home' }"
      class="h-full"
    >
      <div class="flex h-full flex-col p-5 lg:px-12 lg:py-8">
        <notification
          v-if="!isPayeesSyncedBatchOrder"
          class="mb-8"
          icon="heroicons/solid/information-circle"
        >
          Your batch payment will be processed once all payees have been
          approved. You can view the status of this payment by refreshing the
          page, and you can see the status of individual payees via the 'Payees'
          tab.
        </notification>

        <div class="mb-8">
          <h1
            v-if="!ui.mobile"
            class="mb-8 text-2xl leading-none text-eonx-neutral-800"
          >
            Uploaded file details
          </h1>
          <div class="mt-8 flex space-x-5 divide-x">
            <uploaded-file
              :batch-order="batchOrder"
              :on-cancel-order="onCancelBatchOrder"
            />
            <div class="space-x-1 pl-5 font-bold">
              <span class="text-eonx-neutral-600">Total payments:</span>
              <span class="text-xl text-eonx-neutral-800">
                {{ pendingPayees.pagination.totalItems }}
              </span>
            </div>
            <div class="space-x-1 pl-5 font-bold">
              <span class="text-eonx-neutral-600">
                Total amount to be processed:
              </span>
              <span class="text-xl text-eonx-neutral-800">
                {{ formatDollar(batchOrder.initialAmount) }}
              </span>
            </div>
          </div>
          <div class="mt-4">
            *Please note that the reference (sent to payee) will be limited to 9
            characters. For queries, contact support.
          </div>
        </div>

        <pending-payees-table
          :labels="tableLabels"
          :rows="pendingPayees.transactionList.list"
          :loading="pendingPayees.loader.loading"
        />

        <pagination
          v-if="pendingPayees.pagination.totalPages > 1"
          class="mt-8"
          :current-page="pendingPayees.pagination.currentPage"
          :total-pages="pendingPayees.pagination.totalPages"
          @selectPage="pendingPayees.load($event)"
        />

        <controls
          class="sticky bottom-0 mt-8 w-full bg-eonx-neutral-50"
          :is-continue-disabled="!isPayeesSyncedBatchOrder"
          :is-continue-loading="
            pendingPayees.loader.loading ||
            batchOrderStatusHandler.batchOrderLoader.loading
          "
          :cancel-text="cancelButtonText"
          :continue-text="continueButtonText"
          @cancel="onCancelBatchOrder"
          @continue="goToCheckout"
        />
      </div>
    </component>
  </layout-main>
</template>
