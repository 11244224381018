import {
  useBatchOrder,
  useBatchOrderStatusHandler,
} from '/~/composables/batch-order'
import { BatchOrderPendingPayees } from './core/BatchOrderPendingPayees'

export function useBatchOrderPendingPayees() {
  const { batchOrder } = useBatchOrder()

  const { batchOrderStatusHandler } = useBatchOrderStatusHandler()

  const pendingPayees = new BatchOrderPendingPayees({
    url: `/v3/batch-orders/${batchOrder.number}/transactions`,
    perPage: '10',
  })

  function goToCheckout() {
    batchOrderStatusHandler.setReadyToPay()
    batchOrderStatusHandler.startCheckingLoop()
  }

  return {
    pendingPayees,
    goToCheckout,
  }
}
